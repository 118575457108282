<template>
  <div class="ra-basic ra-basic-text">
    <RaQuote italic>
      Don't worry when you are not recognized,
      <br />
      but strive to be worthy of recognition
    </RaQuote>
    <h2>About me:</h2>
    <p>
      <b>{{ ago.js }} years</b> of development experience
      <br v-if="false" />
      <span v-if="false">Lead Frontend Engineer</span>
      <br />
      Founder of
      <RaLink
        href="https://ralmaz.pro/academy"
        follow
        styled
        no-wrap
      >
        <b>Veni Vidi Academy</b>
      </RaLink>
      <br />
      Author of
      <RaLink
        href="https://ralmaz.pro/book"
        follow
        styled
        no-wrap
      >
        <b>Conscious development</b>
      </RaLink>
      book
    </p>
    <p>
      I start to work with frontend in <b>2012</b>, first
      <b>two years</b> with <b>ExtJs</b>, <b>Angular 1</b>, <b>React</b>
      <br />
      and (forgive me God) some <b>JQuery</b> crutches
    </p>
    <p>
      After that I met <b>Vue</b> (release <b>1.1.4</b>), it was love from a first look
      and since that I have been working around its ecosystem
      <b>{{ ago.vue }} years</b>, such as:
      <br />
      <ul>
        <li>
          SPA apps using <b>Vue 2</b> and <b>3</b>
          (both <b>TypeScript</b> and native)
        </li>
        <li>SSR apps using <b>Nuxt.js</b> and other SSR solutions</li>
        <li>
          Desktop applications on <b>Electron</b>
          and own C++ plugins around it
        </li>
        <li>
          Mobile applications on <b>NativeScript</b> and <b>Flutter</b>
        </li>
        <li>Browser extensions (REC policy friendly)</li>
        <li>BFF on <b>Nest.js</b></li>
        <li>
          Video-call apps around <b>WebRTC</b>
          <br />
          and our own C lang microservises for decode different codecs into one and significantly optimize performance and quality on <b>105</b> concurrent users in one WebRTC call, including weak old laptops
        </li>
        <li>eCommerce solutions with team behind <b>Vue Storefront</b> together with Vue.js core team members</li>
        <li>
          Offline mode compatibility for apps
        </li>
        <li>
          Writing tests - <b>Jest</b>,
          <b>Nightwatch</b> or <b>Spectron</b>
        </li>
      </ul>
    </p>
    <br />
    <p>
      I have chosen a path where by giving the end user the best possible quality of life,
      performance, usability and intuitiveness in a product, and by developing it with an understanding of the needs of the business,
      I can do my job in the best possible way, even if it requires more resources than usual development, such us:
      <br />
      <ul>
        <li>More attention to the UI/UX before its even hits the development phase, and also before its finished - developers review every bit of design and user cases</li>
        <li>Running benchmarks and profiler testing for every bit of application, understanding render time spikes, memory leaks, layout shifts and browser incompatibility</li>
        <li>Testing how popular extensions (Adblock/Adguard/Black theme/Price checkers etc) affects our functionality and visual</li>
        <li>Making sure that every member of FE development team knows exactly how the product must work and not letting them incapsulate their knowledge of product on only one feature</li>
      </ul>
    </p>
    <p>
      I am convinced that a lack of attention to details can kill product of any size and make it unusable for the users as a result
    </p>
    <p>
      <b>(caution):</b> In terms of teamworking im assertive and cannot be considered as "agreeable" person
    </p>
    <br />
    <br />
    <p>
      Since <b>2018</b> Im giving free lessons inside our
      <RaLink
        href="https://ralmaz.pro/academy"
        follow
        styled
        no-wrap
      >
        <b>community</b>
      </RaLink>
      for people who have some
      developer experience and for those who don't have any experience
    </p>
    <p>
      I participate in development opensource <b>UI libraries</b>,
      and publicly disclosing some unobvious moments
      of team-working and frontend
      on experience sharing meetings
    </p>
    <br />
    <p>
      Do I have a degree in programming?
      <br />
      <b>No</b>, my degree is business economics
    </p>
    <br />
    <p>
      I believe in several milestones that make the development industry better:
      <br />
      - <b>user</b> and <b>performance</b> oriented development
      <br />
      - no one cares how many hours you work if result is not achieved
      <br />
      - developer should be an <b>engineer</b> and not just a framework user
      <br />
      - <b>effective</b> developers should get much more money than
      <b>ineffective</b><br />
      - <b>unqualified</b> managers have no place in teams<br />
      - the developer should do what the <b>business</b> needs,
      not the whims of <b>managers</b><br />
      - paid overtime<br />
    </p>
    <br />
    <p>
      English:
      <RaLink
        :href="efsetLink"
        styled
        no-wrap
      >
        <b v-text="efset" />
      </RaLink>
    </p>
    <p>
      Psychotype:
      <RaLink
        :href="typeLink"
        styled
        no-wrap
      >
        <b v-text="type" />
      </RaLink>
    </p>
  </div>
</template>

<script>
import RaLink from '@/components/elements/RaLink.vue'
import RaQuote from '@/components/elements/RaQuote.vue'

export default {
  name: 'Home',

  components: {
    RaLink,
    RaQuote
  },

  metaInfo: {
    title: 'About me',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'Roman Almazov - Lead Frontend Engineer'
      },
      { name: 'og:title', content: 'Roman Almazov' },
      { name: 'twitter:title', content: 'Roman Almazov' },
      { name: 'og:description', content: 'Lead Frontend Engineer' },
      { name: 'twitter:description', content: 'Lead Frontend Engineer' },
      { name: 'og:url', content: 'https://ralmaz.pro' },
      { name: 'twitter:url', content: 'https://ralmaz.pro' },

      { name: 'og:type', content: 'profile' },
      { name: 'profile:first_name', content: 'Roman' },
      { name: 'profile:last_name', content: 'Almazov' },
      { name: 'profile:username', content: 'RALMAZ' },
      { name: 'profile:gender', content: 'male' }
    ]
  },

  data: () => ({
    type: 'Assertive Protagonist (ENFJ-A)',
    typeLink: 'https://www.16personalities.com/profiles/1cdd2153eab4e',
    efset: 'C1 - Advanced',
    efsetLink: 'https://www.efset.org/cert/vQPDAf',

    ago: {
      js: 8,
      jsFrom: '2012-03-10',
      vue: 4,
      vueFrom: '2015-08-11'
    }
  }),

  mounted() {
    this.dataRanges()
  },

  methods: {
    dataRanges() {
      this.ago.js = this.calculateYears(new Date(this.ago.jsFrom))
      this.ago.vue = this.calculateYears(new Date(this.ago.vueFrom))
    },

    calculateYears(start) {
      const ageDifMs = Date.now() - start
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getFullYear() - 1970)
    }
  }
}
</script>
